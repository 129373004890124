<template>
    <div class="searind">
        <div>
            <el-input class="search" v-model="nameLike" placeholder="指标名称" clearable prefix-icon="el-icon-search" @keyup.enter.native="searchList" @blur="searchList" @clear="searchList">
            </el-input>
        </div>
        <div>
            <el-select v-model="searstatus" clearable placeholder="请选择状态" @change="searChange(searstatus)">
                <el-option v-for="item in statusoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nameLike: "",
            searstatus: "",
            statusoptions: [
                { value: "0", label: "启用" },
                { value: "1", label: "停用" },
            ],
        };
    },
    methods: {
        searchList() {
            // console.log(this.nameLike);
            this.$emit("searchList", this.nameLike);
        },
        searChange(val) {
            // console.log(val);
            this.$emit("searChange", val);
        },
        clear() {
            this.nameLike = "";
            this.searstatus = "";
            this.$emit("searchList", "");
            this.$emit("searChange", "");
        },
    },
};
</script>

<style scoped>
</style>
